// import axios from "axios"
import Api from '../../config/config.js'

export default {
    async post(options){
        try {
            let postCategorie = await Api.instance.post(`/categorie/generate`,options)
            console.log("postCategorie",postCategorie)
            return postCategorie?true:false
        } catch (error) {
            console.log(error)
        }
    },
    async get(){
        try {
            let getCategorie = await Api.instance.get(`/categorie/get`)
            console.log("getCategorie",getCategorie)
            return getCategorie.data
        } catch (error) {
            console.log(error)
        }
    },

    async put(options){
        try {
            let putCategorie = await Api.instance.put(`/categorie/update`,options)
            console.log("putCategorie",putCategorie)
            return putCategorie.data.update
        } catch (error) {
            console.log(error)
        }
    },

    async getByTypeAndIscription(options){
        try {
            let getCategorie = await Api.instance.get(`/categorie/get-by-type-and-Inscription`,{params:options})
            console.log("getCategorieBytypeandInscription",getCategorie)
            return getCategorie.data
        } catch (error) {
            console.log(error)
        }
    },
    
    async getByArrayTypeAndIscription(options){
        try {
            let getCategorie = await Api.instance.get(`/categorie/get-by-array-type-and-Inscription`,{params:options})
            console.log("getByArrayTypeAndIscription",getCategorie)
            return getCategorie.data
        } catch (error) {
            console.log(error)
        }
    },

    async delete(options){
        try {
            let deleteCategorie = await Api.instance.post(`/categorie/delete`,options)
            console.log("deleteCategorie",deleteCategorie)
            return deleteCategorie.data.delete
        } catch (error) {
            console.log(error)
        }
    }
}