// import axios from "axios"
import Api from '../../config/config.js'

export default {
    async post(options){
        try {
            let postHoraireDay = await Api.instance.post(`/horaire-day/generate`,options)
            console.log("postHoraireDays",postHoraireDay)
            return postHoraireDay.data.create
        } catch (error) {
            console.log(error)
        }
    },
    async getCreneauxByArraySaisonTypeCategorieSalle(options){
        try {
            let getCreneau = await Api.instance.get(`/horaire-day/get-by-array-saison-type-categorie-salle`,{params:options})
            console.log("getHoraireDay creneau",getCreneau)
            return getCreneau.data
        } catch (error) {
            console.log(error)
        }
    },    
    async put(options){
        try {
            let putHoraireDay = await Api.instance.put(`/horaire-day/update`,options)
            console.log("putHoraireDay",putHoraireDay)
            return putHoraireDay.data.update
        } catch (error) {
            console.log(error)
        }
    },
    async delete(options){
        try {
            let deleteHoraireDay = await Api.instance.post(`/horaire-day/delete`,options)
            console.log("deleteHoraireDay",deleteHoraireDay)
            return deleteHoraireDay.data.delete
        } catch (error) {
            console.log(error)
        }
    }    
}
