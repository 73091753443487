// import axios from "axios"
import Api from '../../config/config.js'

export default {
    async post(options){
        try {
            let postHoraire = await Api.instance.post(`/horaire/generate`,options)
            console.log("postHoraire",postHoraire)
            return postHoraire.data.create
        } catch (error) {
            console.log(error)
        }
    },
    async getBySaison(options){
        try {
            let getHoraire = await Api.instance.get(`/horaire/get-by-saison`,{params:options})
            console.log("getHoraire",getHoraire)
            return getHoraire.data
        } catch (error) {
            console.log(error)
        }
    },
    async getByArraySaisonTypeCategorie(options){
        try {
            let getSalle = await Api.instance.get(`/horaire/get-salle-by-array-saison-type-categorie`,{params:options})
            console.log("getHoraire salle",getSalle)
            return getSalle.data
        } catch (error) {
            console.log(error)
        }
    },
    async put(options){
        try {
            let putHoraire = await Api.instance.put(`/horaire/update`,options)
            console.log("putHoraire",putHoraire)
            return putHoraire.data.update
        } catch (error) {
            console.log(error)
        }
    },
    async delete(options){
        try {
            let deleteHoraire = await Api.instance.post(`/horaire/delete`,options)
            console.log("deleteHoraire",deleteHoraire)
            return deleteHoraire.data.delete
        } catch (error) {
            console.log(error)
        }
    }    
}